define("discourse/plugins/discourse-gridlock-mm-auth/initializers/discourse-gridlock-mm-auth", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "gridlock-mm-auth",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        const fetchAndStoreToken = () => {
          if (api.getCurrentUser()) {
            // User is logged in, fetch the token
            (0, _ajax.ajax)("/gridlock-mm-auth/fetch-token", {
              type: "GET"
            }).then(response => {
              if (response.success && response.data) {
                // Store the token in localStorage
                localStorage.setItem("gridlock-mm-auth", JSON.stringify(response.data));
              } else {
                console.error("Failed to retrieve Gridlock MM Auth token");
                localStorage.removeItem("gridlock-mm-auth");
              }
            }).catch(error => {
              console.error("Error fetching Gridlock MM Auth token:", error);
              localStorage.removeItem("gridlock-mm-auth");
            });
          } else {
            // User is not logged in, clear the token
            localStorage.removeItem("gridlock-mm-auth");
          }
        };

        // Fetch token on page load
        fetchAndStoreToken();

        // Fetch token on page changes
        api.onPageChange(() => {
          fetchAndStoreToken();
        });
      });
    }
  };
});